<template>
    <v-container v-if="timer && duration" class="countdown">
        <div class="days unit-wrap">
            <span class="unit">{{ days }}</span>
            Days
        </div>
        <span class="hours unit-wrap">
            <span class="unit">{{ hours }}</span>
            Hours
        </span>
        <span class="minutes unit-wrap">
            <span class="unit">{{ minutes }}</span>
            Minutes
        </span>
        <span class="seconds unit-wrap">
            <span class="unit">{{ seconds }}</span>
            Seconds
        </span>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import dayjs, { Dayjs } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';

const ACountdownProps = Vue.extend({
    props: {
        end: {
            type: Number,
            required: true
        }
    }
});

@Component({})
export default class ACountdownTimer extends ACountdownProps {
    endTime: Dayjs = dayjs(this.end * 1000);

    timer: ReturnType<typeof setInterval> = setInterval(() => {
        this.calculateDuration();
    }, 1000);

    duration: Duration = dayjs.duration(0);

    mounted() {
        this.calculateDuration();
    }

    calculateDuration() {
        const now = dayjs();
        if (this.endTime.isBefore(now)) {
            this.duration = dayjs.duration(0);
        }

        this.duration = dayjs.duration(this.endTime.diff(now));
    }

    get days() {
        return this.duration.format('DD');
    }

    get hours() {
        return this.duration.format('HH');
    }

    get minutes() {
        return this.duration.format('mm');
    }

    get seconds() {
        return this.duration.format('ss');
    }

    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>
<style lang="scss" scoped>
.countdown {
    .unit-wrap {
        color: $secondary-color;
        display: inline-block;
        font-size: 1em;
        text-align: center;
        min-width: 75px;
        margin: 0 0.5em;
        .unit {
            color: $primary-color;
            display: block;
            font-size: 2em;
            font-weight: bold;
        }
    }
}
</style>
